






































import { ref as _ref } from "@vue/composition-api";
import { defaultListQuery } from "@/mixins";
import Teams, { TeamData } from "@/select2/team";
import Pagination from "@/components/Pagination/index.vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const isAdmin = User.isAdmin;

  let count = _ref(0);

  let listQuery = _ref({ ...defaultListQuery,
    page: 1,
    page_size: 20
  });

  let loading = _ref(true);

  let teams = _ref(([] as TeamData[]));

  async function load() {
    function join(xs: Lookup[]): string {
      const s = xs.map(x => x.name).join(", ");
      if (s.length <= 80) return s;
      return s.slice(0, 77) + "...";
    }

    loading.value = true;
    const data = await Teams.list(listQuery.value);
    teams.value = data.results.map(t => ({ ...t,
      advertisers: join(t.advertisers),
      users: join(t.users),
      agency: t.agency?.name
    }));
    count.value = data.count;
    loading.value = false;
  }

  async function search() {
    listQuery.value.page = 1;
    load();
  }

  onMounted(() => {
    load();
  });
  return {
    isAdmin,
    count,
    listQuery,
    loading,
    teams,
    load,
    search
  };
};

__sfc_main.components = Object.assign({
  Pagination
}, __sfc_main.components);
export default __sfc_main;
