import { ListParams } from "./api/base"
import Generate from "./api/crud"
import { Lookup } from "./base"

interface Params extends ListParams {
	advertiser?: number
}

export interface Team {
	id?: number
	name: string
	advertisers: string[]
	agency?: Lookup | number
	users: number[]
}

export interface TeamForm extends Omit<Team, "id"> {
	agency: number
}

export interface TeamData extends Team {
	id: number
	agency: Lookup
}

export const defaultTeamData: Team = {
	name: "",
	advertisers: [],
	users: [],
}

export default Generate<TeamData, TeamData>("/teams")
